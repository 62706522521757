.providersContainer {
  display: flex;
  justify-content: center;
  margin: 15px auto 25px;
  max-width: 900px;
  width: 100%;
}
.contentContainer {
  width: 100%;
  flex: 1;
  margin-top: 0;

  display: flex;
  flex-direction: column;
}

.modalFooter {
  font-size: 14px;
  color: #5c5b66;
  margin: 17px 0 0;
}
.modalButton {
  max-width: 136px;
  margin: 10px auto 17px;
}