.continueContainer {
  margin: 40px auto 50px;
  width: 100%;
  flex: 1;
  max-width: 958px !important;
  padding: 0 !important;
}
.continueContainerMobile {
  padding: 0 30px !important;
}
.continueContainer h1 {
  margin: 0 0 38px;
  font-weight: 500;
  font-size: 28px;
  line-height: 43px;

  color: #454452;
}
.continueContainer h5 {
  margin: 0 0 45px;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;

  color: #5C5B66;
}
.continueContainer ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  width: 480px;
}
.continueContainer li {
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 35px;
  border-bottom: 1px solid #E5E4EE;
}
.continueContainer li:last-child {
  margin-bottom: 0;
}
.continueContainer li h5 {
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-transform: uppercase;

  color: #B7B3CE;
}
.continueContainer li div {
  margin: 0;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  text-transform: capitalize;
  
  color: #B7B3CE;
}
.continueContainer .selected h5 {
  color: #858297;
}
.continueContainer .selected div {
  color: #454452;
  display: inline-block;
}
.continueContainer .selected svg {
  width: 34px;
  height: 34px;
  position: absolute;
  top: 12px;
  right: 0;
}
.continueContainer .continueButton {
  margin-left: 15px;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.04em;

  color: #31E495;
}
.container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.containerMobile ul {
  width: 100%;
}
.container>div {
  flex: 1;
  text-align: center;
}
.containerMobile>div {
  display: none;
}