.footer {
  height: 89px;
  border-top: 1px solid #e4e4e4;
}
.SPTheme a,
.SPTheme a:hover,
.SPTheme a:focus {
  color: #0459FF;
  outline: none;
}
.SPTheme .rightSide svg path {
  fill: #0459FF;
}

.footerMobile {
  height: 200px;
}
.footerContainer {
  display: flex;
  height: 100%;
  align-items: start;
  justify-content: space-between;
  padding: 27px 0 0;
}
.footerContainerMobile {
  flex-direction: column;
  padding: 37px 30px 40px !important;
  align-items: flex-start;
}
.leftSide {
  display: flex;
  align-items: start;
  flex-direction: column;
  flex: 1;
}
.footerContainer p {
  color: #5c5b66;
  font-size: 14px;
  margin: 0 15px 0 0;
}
.separator {
  color: #5c5b66;
  margin: 0 5px;
}
.links {
  font-size: 14px;
  margin-bottom: 9px;
}
.rightSide {
  flex: 1;

  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.rightSide>p {
  height: 21px;
}
.rightSide>.link {
  margin-right: 12px;
}
.rightSideMobile {
  width: 100%;
  justify-content: space-between;
}
.link:last-child {
  margin-right: 0;
}

.leftSideMobile {
  flex: none;
}
.leftSideMobile .links {
  margin: 0;
}
.address svg {
  margin: 0 5px 0 1px;
}
.footer .address {
  margin-left: 20px;
}
.footerMobile .address {
  margin: 15px 0 15px;
}
.leftSideMobile p {
  margin: 15px 0 0px;
}