.cardContainer {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;

  border-radius: 6px;

  height: 418px;
  width: 280px;

  color: #16294c;
  border: 1px solid #31e495;

  margin: 0 auto;
}

.providerHeader {
  width: 100%;
  min-height: 61px;

  position: relative;
  background-color: #31e495;
}
.providerHeader>svg:first-child {
  position: absolute;
  top: 19px;
  left: 32px;
}
.providerHeader>svg:nth-child(2) {
  position: absolute;
  top: 16px;
  transform: translateX(-50%);
  left: 50%;
}
.providerPhoto {
  height: 70px;
  width: 70px;

  border: 2px solid #fff;

  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #eee;

  position: absolute;
  top: 26px;
  left: 50%;
  transform: translateX(-50%);

  box-shadow: 0px 4px 9px rgba(193, 186, 186, 0.25);
}

.providerName {
  margin: 45px auto 0;
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  color: #454452;
}
.providerType {
  margin: 0 auto 15px;
  font-weight: normal;
  line-height: 18px;
  font-size: 14px;
  color: #858297;
}
.countries {
  display: flex;
  padding: 0 20px 14px;
  width: 100%;  
}
.visaTypes {
  flex: 1;
  display: flex;
  padding: 0 20px;
  width: 100%;
}
.countries>span:first-child,
.visaTypes>span:first-child {
  width: 75px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #858297;
}
.countries>span:last-child,
.visaTypes>span:last-child {
  margin-left: 20px;
  flex: 1;
  color: #454452;
  font-size: 14px;
  line-height: 21px;
}
.visaTypes>span:last-child {
  max-height: 63px;
}
.details {
  height: auto;
  padding: 0 3px 18px;
  width: 100%;
  color: #31e495;

  font-size: 14px;
  font-weight: normal;
  text-decoration: underline;
  background-color: #fff;
}
.details p {
  margin: 0;
}
.contactContainer {
  margin: 0 auto 49px;
}
.clickContact {
  margin: 0 auto 20px;
}
.Button {
  width: 156px;
  margin: 0 auto;
}
.details:focus {
  outline: none;
  color: #31e495;
}
.copyTextContainer {
  text-transform: lowercase;
  position: relative;
  height: fit-content;
  margin-top: 9px;
  display: flex;
}
.footerMobile .copyTextContainer {
  margin: 0;
}
.copyTextContainer div {
  position: absolute;
  top: -53px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  
  background: #EAFFF6;
  border: 1px solid #31E495;
  border-radius: 2px;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.05);
  
  line-height: 20px;
  font-size: 14px;
  font-weight: normal;
  white-space: nowrap;
  color: #31E495;
}
.copyTextContainer input {
  flex: 1;
  height: 19px;

  border: 0;
  padding: 0;
  resize: none;

  text-align: right;
  
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  font-size: 16px;
  color: #454452;
}
.copyTextContainer svg {
  width: 21px;
  margin: 0 0 0 8.31px !important;
}