.providerContainer {
  position: relative;
  background-color: #fff;
  margin-top: 30px;
  width: 100%;
  border: 1px solid #E4E4E4;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border-bottom: 0;
  flex: 1;

  display: flex;
  flex-direction: column;
  padding: 20px 25px 0 !important;
  max-width: 800px !important;
}
.SPTheme.providerContainer {
  border: 1px solid #e4e4e4;
  border-radius: 6px;
  padding: 20px 25px 20px !important;
  margin-bottom: 30px;
}
.providerContainerMobile {
  margin: 20px 20px 0 !important;
  width: auto;
}
.backgroundHeader {
  position: absolute;
  top: 80px;
  left: 0;
  right: 0;
  height: 210px;
  background-color: #31e495;
}
.backgroundHeader.SPTheme {
  background-color: #0459FF;
}
.backgroundHeaderMobile {
  top: 70px;
  height: 171px;
}
.providerInformation {
  display: flex;
  border-bottom: 1px solid #e4e4e4;
}
.infoBlock {
  flex: 1;
}
.providerContact {
  margin-top: 22px;
  max-width: 196px;
}
.providerDetail {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.providerPhotoContainer {
  margin-right: 20px;
  width: 90px;
  height: 90px;

  border: 1px solid #a4f6d2;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
}
.SPTheme .providerPhotoContainer {
  border: 1px solid #B3CDFF;
}
.providerPhoto {
  height: 70px;
  width: 70px;

  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #eee;
}

.providerName {
  font-size: 20px;
  line-height: 26px;
  color: #454452;
}
.providerType {
  font-size: 14px;
  line-height: 18.2px;
  color: #858297;
}
.propsTypes {
  width: 100%; 
  margin-bottom: 16px; 
}
.propsTypes>div:first-child {
  font-size: 14px;
  line-height: 18px;
  color: #858297;
  margin-bottom: 5px;
}
.propsTypes>div:last-child {
  color: #454452;
  font-size: 14px;
  line-height: 20px;
}
.providerDescription {
  margin-top: 17px;
  padding-right: 10px;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: .04em;

  color: #5c5b66;
  white-space: pre-line;
  text-align: start;

  flex: 1 1 auto;
  overflow-y: auto;
  height: 0px;
}
.providerDescriptionMobile {
  overflow-y: hidden;
  height: auto;
  margin-bottom: 90px;
}
.footerMobile {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  height: 90px;
  background-color: #fff;
  border-top: 1px solid #e4e4e4;

  display: flex;
  align-items: center;
  justify-content: center;
}
.SPTheme .providerDescription {
  height: auto;
}
.footerMobile .providerContact {
  margin: 0;
}
.modalFooter {
  font-size: 14px;
  color: #5c5b66;
  margin: 17px 0 0;
}
.modalButton {
  max-width: 136px;
  margin: 10px auto 17px;
}

.contactContainer {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.copyTextContainer {
  text-transform: lowercase;
  position: relative;
  height: fit-content;
  margin-top: 20px;
  display: flex;
}
.footerMobile .copyTextContainer {
  margin: 0;
}
.copyTextContainer div {
  position: absolute;
  top: -53px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  
  background: #EAFFF6;
  border: 1px solid #31E495;
  border-radius: 2px;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.05);
  
  line-height: 20px;
  font-size: 14px;
  font-weight: normal;
  white-space: nowrap;
  color: #31E495;
}
.copyTextContainer input {
  flex: 1;
  height: 19px;

  border: 0;
  padding: 0;
  resize: none;

  text-align: right;
  
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  font-size: 16px;
  color: #454452;
}
.copyTextContainer svg {
  width: 21px;
  margin: 0 0 0 8.31px !important;
}

/*custom scroll*/
.providerDescription::-webkit-scrollbar {
  width: 8px;
}
.providerDescription::-webkit-scrollbar-track {
  background: transparent;
}
.providerDescription::-webkit-scrollbar>* {
  background:transparent;
}
.providerDescription::-webkit-scrollbar-thumb {
  background: #E7E5F1; 
  border-radius: 80px;
}
.providerDescription::-webkit-scrollbar-thumb:hover {
  background: #E1DFEF;
}
.providerDescription::-webkit-scrollbar-thumb:active {
  background: #D1CFE5;
}