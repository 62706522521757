.pageTitle {
  text-align: center;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 500;
  color: #454452;
  margin: 35px 0 33px;
}
.pageTitle span {
  color: #31e495;
}
.pageTitleMobile {
  font-size: 20px;
}
.title {
  margin: 36px auto 4px;
  font-weight: normal;
  line-height: 24px;
  height: 31px;
  font-size: 18px;
  text-align: center;

  color: #5C5B66;
}
.pagesubTitleMobile {
  margin: 37px auto 22px;
  height: auto;
}