.cardContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  
  border-radius: 6px;
  position: relative;
  margin-left: auto;
  margin-right: auto;

  height: 176px;
  width: 280px;
  color: #fff;
  text-decoration: none;
}
.default {
  background-color: #eee;
}
.cardContainerMobile {
  flex-direction: row;
}
.cardContainer>svg {
  margin: 0 0 15px 0;
}
.cardContainerMobile>svg {
  margin: 0 15px 0 0;
}
.cardContainer:focus {
  color: #fff;
}
.background {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  transition: all .3s;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.cardContainer:hover .background {
  box-shadow: inset 0 0 0 2000px rgba(16, 28, 99, 0.25);
}
.cardValue {
  font-size: 22px;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.cardContainerMobile .cardValue {
  font-size: 20px;
}


.visaType {
  width: 200px;
  height: 180px;
  background-color: #304ffe;
}
.visaTypeLabel {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: .8px;
  text-align: center;
}
.cardContainerMobile .visaTypeLabel {
  font-size: 20px;
  font-weight: 400;
}
.visaTypeLabelMobile {
  font-size: 18px;
  letter-spacing: .72px;
}
.visaTypeMobile {
  width: 280px;
  height: 90px;
}
.visaType:hover {
  color: #fff;
  background-color: #2b4af8;
}
.visaType:focus {
  color: #fff;
  outline: none;
  background-color: #2340e5;
}


.any {
  background-color: #31e495;
}
.anyMobile {
  height: 65px;
}
.any>div {
  font-weight: 400;
}
.anyMobile>div {
  font-size: 18px;
  letter-spacing: .72px;
}
.any:hover {
  color: #fff;
  background-color: #2eda8f;
}
.any:focus {
  color: #fff;
  outline: none;
  background-color: #35c988;
}