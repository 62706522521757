.resultsContainer {
  margin: 40px auto;
  width: 100%;
  flex: 1;
  max-width: 958px !important;
  padding: 0 !important;
}
.resultsContainerMobile {
  padding: 0 30px !important;
}
.resultsContainer h1 {
  margin: 0 0 20px;
  font-weight: 500;
  font-size: 28px;
  line-height: 43px;

  color: #454452;
}
.resultsContainerMobile h1 {
  margin: 0 0 30px;
}
.resultsContainer>p {
  margin: 0 0 5px;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  color: #5C5B66;

}
.resultsContainerMobile>p {
  margin: 0 0 15px;
}
.resultsContainer h5 {
  margin: 0 0 28px;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;

  color: #5C5B66;

}

.container {
  display: flex;
}
.resultsContainerMobile .container {
  flex-direction: column-reverse;
}
.visasContainer {
  flex: 1;
  padding: 40px;
  border: 1px dashed #B7B3CE;
  margin-right: 40px;
}
.visaContainer {
  max-width: 400px;
}
.visaContainer .visaBlock {
  margin-left: auto;
  margin-right: auto;
}
.resultsContainerMobile .visasContainer {
  padding: 30px;
  margin: 0 auto;
  width: 300px;
}
.visasContainer>div {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.visasContainer .visaBlock {
  flex: 1 0 50%;
  margin-bottom: 20px;
}
.visaTravelContainer {
  width: 320px;
  height: 326px;
  padding: 40px;
  border: 1px dashed #B7B3CE;
}
.resultsContainerMobile .visaTravelContainer {
  padding: 30px;
  width: 300px;
  margin: 0 auto 30px;
}
.visaButton {
  max-width: 319px;
  margin: auto;
  height: 46px;
  width: 100%;

  padding: 0;
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  font-size: 14px;
  line-height: 17px;
  letter-spacing: .04em;
  
  border: 1px solid transparent;
  border-radius: 30px;
  text-decoration: none;
  text-transform: uppercase;

  transition: .3s;
  color: #fff;
  background: #31E495;
}
.visaButton:hover {
  cursor: pointer;
  background-color: #2eda8f;
}
.visaButton:focus {
  color: #fff;
}
.visaButton:active {
  background-color: #35c988;
}

.resultsContainerMobile .visaButton {
  max-width: 239px;
  text-align: center;
}
.visaTravelButton {
  margin-top: 20px;
  max-width: 239px;
}

.visaBlock {
  max-width: 239px;
  height: 178px;
  background: #EAFFF6;
  border-radius: 4px;
  padding: 15px 20px 5px;
}
.visaTravelBlock {
  border: 1px solid #31E495;
  background: #fff;
  border-radius: 4px;
  margin: 0 0 20px;
}
.visaTitle {
  display: flex;
  align-items: center;

  font-weight: 500;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: 0.04em;
  text-transform: capitalize;

  color: #31E495;
  padding-bottom: 10px;
  border-bottom: 1px solid #31e495;
}
.visaTitle svg {
  width: 25px;
  margin-right: 10px;
}
.visaDescription {
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;

  color: #5C5B66;
  padding-top: 10px;
}