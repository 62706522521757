.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 0;
}
.visaContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0px -15px 35px;
}
.contentContainer {
  width: 100%;
  flex: 1;
  margin-top: 0;

  display: flex;
  flex-direction: column;
}
.title {
  margin-top: 0;
}