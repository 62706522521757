.logoMobile svg {
  height: 30px;
}
.mobile svg {
  height: auto;
}
.mobile svg rect:nth-child(3){
  fill: #1D1F52;
}
.userLogoContainer {
  height: 35px;
  width: 35px;

  border: 2px solid #31e495;
  border-radius: 50%;

  background-color: #a0ffd6;

  display: flex;
  justify-content: center;
  align-items: center;

}
.userLogoContainerMobile {
  height: 30px;
  width: 30px; 
}
.userLogoContainer > span {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: .26px;
  color: #38364b;
  text-transform: uppercase;
}
.userLogoContainer:hover {
  cursor: pointer;
}