.headerContainer {
  height: 80px;
  background: #38364B;
  width: 100%;
}
.headerContainer.isOpen {
  position: relative;
}
.headerContainer.isOpen:before {
  content: "";

  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background-color: rgba(68, 66, 81, 0.65);
}
.hideMobile {
  display: none;
}
.headerMobile {
  height: 70px;
}
.headerMobile .contentContainer {
  padding: 0 30px !important;
}
.headerMobile .rightSide button {
  margin-left: 22px;
}
.contentContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}
.leftSide {
  display: flex;
  align-items: center;
}
.leftSide svg {
  height: 27px;
}
.rightSide {
  flex: 1;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}
