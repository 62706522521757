.baseHeaderMobileMenu,
.baseHeaderMobileSearch {
  position: fixed;
  z-index: 10;
  top: 100%;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: #fff;

  margin: 70px 0 0;
  padding: 77px 35px 35px;

  list-style: none;
  transition: all .3s ease 0s;
}
.visibleAssessment,
.visibleInfo,
.visibleMenu {
  top: 0;
}
.baseHeaderMobileMenu > li {
  margin-bottom: 55px;
}
.baseHeaderMobileMenu > li > a,
.baseHeaderMobileMenu > li > div {
  display: block;
  text-decoration: none;
  font-size: 20px;
  line-height: 26px;
  color: #454452;
}
.baseHeaderMobileMenu > li > a:hover,
.baseHeaderMobileMenu > li > div:hover,
.baseHeaderMobileMenu > li > a:focus {
  color: #31e495;
}
.baseHeaderMobileMenu>.close {
  position: absolute;
  top: 25px;
  right: 30px;
  padding: 5px;
  margin: 0;
}
.baseHeaderMobileMenu .separator {
  background-color: #e4e4e4;
  height: 1px;
  width: 100%;
  margin: 0 0 52px;
}
.baseHeaderMobileSearch .baseHeaderMobileSearchContainer {
  position: relative;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 60px 20px 0;
}
.baseHeaderMobileSearchContainer > .close {
  padding: 12px;

  position: absolute;
  right: 0;
  top: 0;
}
.baseHeaderMobileSearchContainer > .searchContainer > input {
  width: 100%;
  padding: 10px;

  border: 1px solid #eee;
  border-radius: 15px;
  
  font-size: 14px;
}

.ButtonLink,
.Button {
  max-width: 136px;
  float: right;
  margin-left: 35px;
  line-height: 17px;
  letter-spacing: .56px;
  font-size: 14px;
  font-weight: 400;
}
.ButtonLink {
  color: #31e495;
  font-weight: 400;
  text-decoration: underline;
}
.separator {
  color: #fff;
  margin-left: 27px;
}
.Button {
  height: 100%;
  border-radius: 0;
  border-bottom: 0;
}
.selected,
.Button:hover {
  box-shadow: inset 0px -3px 0px 0px #31e495;
}
.SPTheme.selected,
.SPTheme.Button:hover {
  box-shadow: inset 0px -3px 0px 0px #0459FF;
}
.Button:focus {
  color: #fff;
}

.userMenu {
  display: flex;
  flex-direction: column;

  top: 50px;
  right: -10px;
  z-index: 2;
  
  width: 275px;
  height: 288px;
  
  background-color: #fff;
  box-shadow: 0 4px 20px rgba(103,105,118, 0.19);
}
.userMenu .separator {
  background-color: #e4e4e4;
  height: 1px;
  width: 100%;
  margin: 0 0 37px;
}
.userMenu a {
  display: block;
  font-size: 14px;
  line-height: 17px;
  color: #454452;
  text-decoration: none;
  margin-bottom: 31px;
}
.count {
  width: 20px;
  height: 20px;
  margin-left: 5px;
  background-color: #31e495;
  
  display: inline-flex;
  align-items: center;
  justify-content: center;
  
  border-radius: 15px;
  
  font-weight: 500;
  font-size: 9px;
  line-height: normal;
  color: #000;
}
.count.SPTheme {
  background-color: #0459FF;
  color: #fff;
}
.userMenu a:last-child {
  margin-bottom: 0;
}
.userMenu a:hover,
.userMenu a:focus {
  color: #31e495;
}
.userMenu.SPTheme a:hover,
.userMenu.SPTheme a:focus {
  color:#0459FF;
}
.userMenu>.menuContainer {
  height: 100px;
}
.userMenu.SPTheme svg path {
  fill:#0459FF;
}

.logoContainer {
  height: 100%;
  display: flex;
  align-items: center;
  transition: all .3s;
  margin-left: 16px;
}
.logoContainer:hover {
  box-shadow: inset 0px -3px 0px 0px #31e495;
}
.SPTheme.logoContainer:hover {
  box-shadow: inset 0px -3px 0px 0px #0459FF;
}
.assessmentContainer {
  height: 100%;
  display: flex;
  align-items: center;
  transition: all .3s;
  margin-left: 40px;
}
.assessmentContainer .userMenu {
  height: auto;
  right: -17px;
}
.assessmentContainer:hover {
  box-shadow: inset 0px -3px 0px 0px #31e495;
}
.messages {
  margin-left: 22px;
  overflow: inherit;
  position: relative;
}
.counterContainer {
  position: relative;
}
.withDot {
  overflow: inherit;
}
.withDot:before {
  position: absolute;
  content: "";
	display: block;
  right: -3px;
  top: -3px;
  width: 6px;
  height: 6px;
  background-color: #31e495;
  border-radius: 50%;
}
.counterContainer>div {
  position: absolute;
  right: -5px;
  top: -5px;
  
  width: 15px;
  height: 15px;
  
  background-color: #31e495;
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  border-radius: 15px;
  
  font-weight: 500;
  font-size: 9px;
  line-height: normal;
  color: #000;
}
.counterContainer.SPTheme>div {
  color: #fff;
  background-color: #0459FF;
}
.photo {
  height: 35px;
  width: 35px;

  border: 2px solid #0459FF;
  border-radius: 50%;

  background-color: #eee;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  display: flex;
  justify-content: center;
  align-items: center;
}