.userContainer {
  display: flex;
  margin: 40px auto;
  width: 100%;
  flex: 1;
  max-width: 910px;
}
.SPTheme.userContainer .titleLink {
  color: #0459FF;
  display: block;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.04em;
  margin-bottom: 23px;
}
.pageContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 !important;
  margin-left: 0 !important;
}
.userContainerMobile {
  flex-direction: column;
  padding: 0 15px;
}
.navigation {
  width: 300px;
  border-right: 1px solid #e4e4e4;
}
.userContainerMobile .navigation {
  width: 100%;
  margin: 0;
  border-right: 0;
  border-bottom: 1px solid #e4e4e4;
}
.navigation a:focus {
  color: #31e495;
}
.SPTheme .navigation a:focus {
  color: #0459FF;
}
.content {
  display: flex;
  flex-direction: column;

  flex: 1;
  margin-left: 80px;
  max-width: 540px;
}
.userContainerMobile .content {
  margin: 37px 0 40px;
}
.link {
  font-size: 18px;
  line-height: 23.4px;
  color: #b1afba;
  text-decoration: none;
  display: flex;
  margin-bottom: 20px;
}
.link > svg {
  margin-right: 12px;
}
.link:hover {
  color: #31e495;
}
.link:hover>svg path {
  fill: #31e495;
}
.selected {
  color: #31e495;
}
.selected>svg path {
  fill: #31e495;
}
.SPTheme .link:hover {
  color: #0459FF;
}
.SPTheme .link:hover>svg path {
  fill: #0459FF;
}
.SPTheme .selected {
  color: #0459FF;
}
.SPTheme .selected>svg path {
  fill: #0459FF;
}
.contentTitle {
  margin-bottom: 65px;
}
.providerContainer.contentTitle {
  margin-bottom: 0;
}
.providerContainer h1 {
  margin: 65px 0 30px !important;
}
.providerContainer h1:first-child {
  margin: 0 0 30px !important;
}
.providerContainer .info {
  margin-bottom: 35px;
}
.providerContainer .info:last-child {
  margin-bottom: 0;
}
.providerPhoto {
  margin: 0 30px 35px;
  height: 70px;
  width: 70px;
  border: 2px solid #0459FF;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #eee;
}
.contentTitleMobile {
  margin-bottom: 39px;
}
.formContainer h1,
.contentTitle h1 {
  margin: 0 0 22px;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: .8px;
  color: #454452;
}
.formContainer h1.phone {
  margin: 30px 0 30px -30px;
}
.subTitle {
  margin: 0 30px 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: .56px;
  color: #858297;
}
.info {
  margin: 0 30px;
  font-weight: 500;
  line-height: 26px;
  font-size: 16px;
  letter-spacing: 0.04em;
  color: #454452;
}
.talk {
  margin: 0;
  font-weight: normal;
  line-height: 26px;
  font-size: 14px;
  letter-spacing: 0.04em;
  color: #454452;
}
.talk a {
  color: #31e495;
  font-size: 14px;
  font-weight: 400;
}
.SPTheme .talk {
  color: #454452;
  text-decoration: none;
}
.settingsPageContainer {
  flex: 1;
  display: flex;
  width: 100%;
}
.blockContainer {
  border: 1px solid #b7b3ce;
  margin-left: 30px;
  padding: 28px 40px 26px;
  width: 440px;
}
.blockContainerMobile {
  width: 240px;
  padding: 28px 20px 26px;
} 
.forgot {
  background: #EAFFF6;
  border: 1px solid #31e495;
  padding: 15px 27px;
}
.SPTheme.forgot {
  background: #EDF3FF;
  border: 1px solid #0459FF;
}
.blockContainer a {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: .56px;

  display: flex;
  align-items: center;
}
.SPTheme.blockContainer a {
  color: #0459FF;
}
.SPTheme.blockContainer a svg path {
  fill: #0459FF;
}
.blockContainer h3 {
  margin: 0 0 4px;
  font-weight: 500;
  line-height: 17px;
  font-size: 14px;
  letter-spacing: 0.04em;
  color: #31e495;
  height: auto;
  text-align: left;
}
.SPTheme.blockContainer h3 {
  color: #0459FF;
}
.blockContainer p {
  margin: 0;
  font-weight: normal;
  line-height: 18px;
  font-size: 14px;
  letter-spacing: 0.04em;
  color: #5C5B66;
}

.blockContainer a > svg {
  margin-left: 10px;
}
.form > div {
  position: relative;
  margin: 0 0 35px;
}
.userContainer .form {
  margin-left: 30px;
} 
.userContainerMobile .form {
  margin-right: 30px;
}
.Button {
  max-width: 136px;
  float: right;
  margin-top: 36px;
}
.userContainerMobile .Button {
  margin: 36px auto 0;
  float: none;
}

.DropdownContainer {
  width: 100%;
}
.error {
  margin: 0 0 14px;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: .56px;
}
.error {
  color: #f44336;
}
.confirmationContainer {
  margin: 36px 0 0 !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.success {
  flex: 1;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: .04em;
  color: #31e495;
}

.containerX {
  display: flex;
  align-items: stretch;
  margin: 40px auto;
  width: 100%;
  flex: 1;
  max-width: 960px !important;
}
.noMargin {
  margin: 0 auto;
  padding: 0 !important;
}

.chatListContainer {
  margin: 0 40px 0 0;
  padding: 0 20px;
  width: 355px;
  border: 1px solid #E5E4EE;
  border-radius: 2px;
  max-height: calc(100vh - 250px);
  overflow-y: auto;
}
.chatListContainer::-webkit-scrollbar {
  width: 8px;
}
.chatListContainer::-webkit-scrollbar-track {
  background: transparent;
}
.chatListContainer::-webkit-scrollbar>* {
  background:transparent;
}
.chatListContainer::-webkit-scrollbar-thumb {
  background: #E7E5F1; 
  border-radius: 80px;
}
.chatListContainer::-webkit-scrollbar-thumb:hover {
  background: #E1DFEF;
}
.chatListContainer::-webkit-scrollbar-thumb:active {
  background: #D1CFE5;
}
.chatListContainerMobile {
  margin: 0 auto;
  max-height: inherit;
}
.ChatItem {
  display: flex;
  padding: 26px 0 20px;
  border-bottom: 1px solid #E5E4EE;
  text-decoration: none;
  position: relative;
}
.selectedChat {
  border-left: 3px solid #31E495;
  background: #EAFFF6;
  margin: 0 -20px;
  padding: 26px 20px 20px 17px;
}
.selectedChat .date {
  right: 20px !important;
}
.ChatItem:hover {
  background: #EAFFF6;
  margin: 0 -20px;
  padding: 26px 20px 20px;
}
.selectedChat.ChatItem:hover {
  background: #EAFFF6;
  margin: 0 -20px;
  padding: 26px 20px 20px 17px;
}
.SPTheme .ChatItem:hover {
  background-color: #EDF3FF;
}
.ChatItem:hover .date {
  right: 20px;
}
.SPTheme .selectedChat {
  border-left: 3px solid #0459FF;;
  background: #EDF3FF;;
  margin: 0 -20px;
  padding: 26px 20px 20px 17px;
}
.SPTheme .selectedChat.ChatItem:hover {
  padding: 26px 20px 20px 17px;
}
.SPTheme .selectedChat .date {
  right: 20px !important;
}
.ChatItem:last-child {
  border-bottom: 0;
}
.ChatItem .circle {
  height: 42px;
  width: 42px;
  background-color: #fff;
  border-radius: 50%;
  margin-right: 12px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.SPTheme .ChatItem .circle {
  height: 42px;
  width: 42px;
  background-color: #fff;
  color: #000;
  border-radius: 50%;
  margin-right: 12px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid #B3CDFF;
}
.isRead .circle {
  border: 1px solid #31e495;
}
.SPTheme .isRead .circle {
  border: 1px solid #0459FF;
  box-shadow: 0px 4px 9px rgba(193, 186, 186, 0.25)
}
.ChatItem h5 {
  margin: 0;
  font-weight: normal;
  line-height: 18px;
  font-size: 14px;
  color: #B1AFBA;
}
.isRead h5 {
  color: #454452;
}
.ChatItem p {
  margin: 3px 0 0;
  white-space: pre-line;
  font-weight: normal;
  line-height: 18px;
  font-size: 14px;
  color: #B1AFBA;
  text-transform: none;
}
.isRead p {
  color: #858297;
  position: relative;
  font-weight: 500;
}
.isRead p::after {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  content: "\A";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #31e495;
}
.SPTheme .isRead p::after {
  background: #0459FF;
}
.ChatItem .date {
  position: absolute;
  right: 0;
  top: 18px;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;

  color: #B1AFBA;
}
.ChatItem .container {
  flex: 1;
}

.textBoxContainer {
  border-top: 1px solid #e5e5e5;
  display: flex;
  align-items: center;
  padding-top: 20px;
  background: #fff;
}
.textBoxContainer .textInput {
  max-height: 126px;

  font-weight: normal;
  font-size: 16px;
  letter-spacing: 0.04em;
  color: #454452;

  border: 1px solid #E5E4EE;
  border-radius: 6px;
  padding: 14px;
  flex: 1;
  
  word-break: break-all;
  resize: none;
  overflow: auto;
}
.textBoxContainer .textInput:hover {
  border: 1px solid #B7B3CE;
}
.textBoxContainer .textInput:focus {
  border: 1px solid #454452;
}
.textBoxContainer .textInput::placeholder {
  color: #B1AFBA;
}
.textInput::-webkit-scrollbar {
  width: 4px;
}
.textInput::-webkit-scrollbar-track {
  background: transparent;
}
.textInput::-webkit-scrollbar>* {
  background:transparent;
}
.textInput::-webkit-scrollbar-thumb {
  background: #E7E5F1; 
  border-radius: 80px;
}
.textInput::-webkit-scrollbar-thumb:hover {
  background: #E1DFEF;
}
.textInput::-webkit-scrollbar-thumb:active {
  background: #D1CFE5;
}

.textSubmitButton {
  margin-left: 10px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: #31E495;
  display: flex;
  justify-content: center;
  align-items: center;
}
.textSubmitButton:disabled {
  background: #DFE7F2;
}
.textSubmitButton:disabled svg path {
  fill: #B4C6DC;
}
.textSubmitButton > svg {
  margin-left: -3px;
}
.SPTheme .textSubmitButton {
  background: #0459FF;
}
.SPTheme .textSubmitButton:disabled {
  background: #DFE7F2;
}



.chatContainer {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 250px);
  flex: 1;
}
.chatContainerMobile {
  max-height: calc(100vh - 270px);
}
.chatContainerMobile .textBoxContainer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px 30px;
}
.chatContainer > div {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.chatContainer .talk {
  margin: 0 0 30px;

  font-weight: normal;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.04em;

  color: #454452;
  flex-shrink: 0;
}
.chatContainerMobile .talk {
  margin: -20px -15px 20px;
  padding-bottom: 17px;
  border-bottom: 1px solid #E5E4EE;
  text-align: center;
}
.messagesContainer {
  overflow-y: auto;
  height: 100%;
}
.messagesContainer::-webkit-scrollbar {
  width: 8px;
}
.messagesContainer::-webkit-scrollbar-track {
  background: transparent;
}
.messagesContainer::-webkit-scrollbar>* {
  background:transparent;
}
.messagesContainer::-webkit-scrollbar-thumb {
  background: #E7E5F1; 
  border-radius: 80px;
}
.messagesContainer::-webkit-scrollbar-thumb:hover {
  background: #E1DFEF;
}
.messagesContainer::-webkit-scrollbar-thumb:active {
  background: #D1CFE5;
}

.message {
  display: flex;
  margin: 0 28px 39px 0;
  text-decoration: none;
  position: relative;
}
.chatContainerMobile .message {
  margin: 0px 10px 40px 15px;
}
.message .circle {
  height: 30px;
  width: 30px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #e5e5e5;
  border-radius: 50%;
}
.message .userLogo {
  height: 30px;
  width: 30px;
  background-color: #fff;
}
.SPTheme .message .userLogo {
  border: 1px solid #0459FF;
}
.message h5 {
  margin: 0;
  font-weight: normal;
  line-height: 18px;
  font-size: 14px;
  color: #B1AFBA;
}
.message p {
  word-break: break-all;
  white-space: pre-line;
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 21px;
  letter-spacing: 0.04em;

  color: #454452;
}
.message .date {
  position: absolute;
  bottom: -23px;
  right: 0;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #B1AFBA;
}
.message .container {
  flex: 1;
  margin-left: 10px;
  padding: 20px;
  border: 1px solid #E5E4EE;
  border-radius: 10px;
  border-top-left-radius: 2px;
}
.message .container.from {
  background-color: #F3F2F9;
  border: 1px solid transparent;
}

.ChangePasswordContainer {
  margin-top: -30px;
}
.ChangePasswordContainerMobile {
  margin-top: 0;
}

.noMessages {
  margin: auto;
  width: 100%;
  max-width: 390px;
  height: 248px;
  padding: 46px 64px 51px;
  border: 1px solid #E5E4EE;
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  flex: 1;
}
.noMessages h1 {
  margin: 0;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;

  color: #5C5B66;
}
.noMessages>p {
  margin: 8px 0 20px;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;

  color: #5C5B66;
}
.noMessages .ButtonLink {
  margin: 0 auto;
  max-width: 176px;
}